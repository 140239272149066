body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Arial', sans-serif;
}

.login-container {
  display: flex;
  height: 100vh;
  background-color: #ffffff;
}

.login-form-container {
  flex: 0 0 35%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  padding: 0 2%;
}

.login-form {
  width: 100%;
  max-width: 350px;
  color: #333;
}

.logo {
  width: 150px;
  height: auto;
  margin-bottom: 2rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

h1 {
  font-size: 2.5rem;
  margin-top: 0;
  margin-bottom: 2rem;
  line-height: 1.2;
  font-weight: 600;
  color: #1e2029;
  text-align: center;
}

form {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

input {
  padding: 1rem;
  border-radius: 8px;
  border: 1px solid #d1d1d1;
  background-color: #ffffff;
  color: #333;
  font-size: 1rem;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

input:focus {
  outline: none;
  border-color: #f7931e;
  box-shadow: 0 0 0 2px rgba(247, 147, 30, 0.2);
}

input::placeholder {
  color: #999;
}

button {
  padding: 1rem;
  border-radius: 8px;
  border: none;
  background-color: #f7931e;
  color: white;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.1s ease;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

button:hover {
  background-color: #e88616;
}

button:active {
  transform: translateY(1px);
}

.login-links {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  text-align: center;
}

.login-links a {
  color: #666;
  text-decoration: none;
  font-size: 0.9rem;
  transition: color 0.3s ease;
}

.login-links a:hover {
  color: #f7931e;
}

.login-image {
  flex: 1;
  overflow: hidden;
}

.login-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 768px) {
  .login-container {
    flex-direction: column;
  }
  
  .login-form-container {
    flex: 0 0 auto;
    padding: 2rem;
  }
  
  .login-image {
    flex: 1;
  }
}
